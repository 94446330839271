// eslint-disable-next-line
import { types } from "../types/all";
import GridKnox from "../components/GridKnox";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridCallbackDetails, GridColumns, GridEditRowsModel, GridEventListener, GridRenderCellParams, GridRowModel, GridRowModesModel } from "@mui/x-data-grid";

// eslint-disable-next-line
const typeOf = (obj?: any, type?: string): boolean | string =>
  !!type ? Object.prototype.toString.call(obj).match(/\[object (\w+)\]/)![1] === type : Object.prototype.toString.call(obj).match(/\[object (\w+)\]/)![1];
const round = (num: number) => +(Math.round(parseFloat(num + "e+2")) + "e-2");

function HomePage() {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const intervalId = useRef<any>();
  const { darkMode } = useSelector((state: any) => state.app);
  const [rows_cargos1, setRows_cargos1] = useState<Array<{ [value: string]: any }>>(JSON.parse(localStorage.cargos1 || "[]"));
  const [rows_cargos2, setRows_cargos2] = useState<Array<{ [value: string]: any }>>(JSON.parse(localStorage.cargos2 || "[]"));
  const [rows_clientes, setRows_clientes] = useState<Array<{ [value: string]: any }>>(JSON.parse(localStorage.clientes || "[]"));
  const [rows_precios, setRows_precios] = useState<Array<{ [value: string]: any }>>(JSON.parse(localStorage.precios || "[]"));

  useEffect(() => {
    // dispatch(async (dis: any) => {
    //   setTimeout(() => {
    //     dis({ type: types.dark, [types.dark]: 'dark' });
    //   }, 3000);
    // });

    if (!rows_precios.length) setRows_precios([{ id: 1, nombre: "KWh", precio: 0 }]);
    if (!rows_clientes.length)
      setRows_clientes([
        { id: 1, cliente: "Piso 1", consumo: 0, usar: "", total: 0 },
        { id: 2, cliente: "Piso 2", consumo: 0, usar: "", total: 0 },
      ]);
    if (!rows_cargos1.length)
      setRows_cargos1([
        { id: 1, cargo: "Cargo Fijo", valor: 0, editable: true, removable: false },
        { id: 2, cargo: "Reposición y Mantenimiento", valor: 0, editable: true, removable: false },
        { id: 3, cargo: "Energía Activa", valor: 0, editable: false, removable: false },
        { id: 4, cargo: "Alumbrado Público", valor: 0, editable: true, removable: false },
        { id: 5, cargo: "Adicionales", valor: 0 },
      ]);
    if (!rows_cargos2.length)
      setRows_cargos2([
        { id: 1, cargo: "Impuesto a la venta", valor: 0, editable: false, removable: false },
        { id: 2, cargo: "Fraccionamiento", valor: 0, editable: true, removable: true },
        { id: 3, cargo: "Aporte de Ley Nro. #####", valor: 0, editable: true, removable: false },
        { id: 4, cargo: "Adicionales", valor: 0 },
      ]);

    return () => {
      clearTimeout(intervalId.current);
    };
    // eslint-disable-next-line
  }, []);

  const cols_cargos1: GridColumns<{ [value: string]: any }> = [
    { field: "cargo", headerName: "Tarifa", minWidth: 130, flex: 0.7, editable: true },
    { field: "valor", headerName: "Valor", minWidth: 130, flex: 0.7, editable: true, type: "number" },
  ];

  const cols_cargos2: GridColumns<{ [value: string]: any }> = [
    { field: "cargo", headerName: "Tarifa", minWidth: 130, flex: 0.7, editable: true },
    { field: "valor", headerName: "Valor", minWidth: 130, flex: 0.7, editable: true, type: "number" },
  ];

  const cols_precios: GridColumns<{ [value: string]: any }> = [
    { field: "nombre", headerName: "Nombre", minWidth: 130, flex: 1, editable: true },
    { field: "precio", headerName: "Precio", minWidth: 130, flex: 1, editable: true, type: "number" },
  ];

  const cols_clientes: GridColumns<{ [value: string]: any }> = [
    { field: "cliente", headerName: "Cliente", minWidth: 110, flex: 0.9, editable: true },
    { field: "consumo", headerName: "Consumo", minWidth: 90, flex: 1, editable: true, type: "number" },
    {
      field: "usar",
      headerName: "Usar",
      minWidth: 60,
      flex: 0.7,
      editable: true,
      type: "singleSelect",
      valueOptions: rows_precios.map((val) => ({ label: val.nombre, value: val.id })),
      renderCell: (params: GridRenderCellParams) => rows_precios.find((val) => val.id === params.value)?.nombre || "",
    },
    {
      field: "total",
      headerName: "Total",
      minWidth: 80,
      flex: 0.1,
      type: "number",
    },
  ];

  const [subtotal, setSubtotal] = useState(0);
  const [cargos, setCargos] = useState(0);
  const [igv, setIgv] = useState(0);

  const update = useCallback(
    (type: "cargos1" | "cargos2" | "precios" | "clientes", arr: Array<any>) => {
      const [cargos1, cargos2, precios, clientes] = [
        type === "cargos1" ? arr : rows_cargos1,
        type === "cargos2" ? arr : rows_cargos2,
        type === "precios" ? arr : rows_precios,
        type === "clientes" ? arr : rows_clientes,
      ];

      localStorage.setItem("cargos1", JSON.stringify(cargos1));
      localStorage.setItem("cargos2", JSON.stringify(cargos2));
      localStorage.setItem("precios", JSON.stringify(precios));
      localStorage.setItem("clientes", JSON.stringify(clientes));

      let cargo1 = 0,
        cargo2 = 0,
        subtotal = 0;

      cargos2.forEach((val: any) => val.id !== 1 && (cargo2 += val.valor));
      cargos1.forEach((val: any) => val.id !== 3 && (cargo1 += val.valor));
      clientes.forEach((val: any) => (subtotal += val.consumo * (precios.find((dat) => dat.id === val.usar)?.precio || 0)));

      const [cargos, igv] = [cargo1 + cargo2, round((subtotal + cargo1) * 0.18)];

      // console.log('clientes.length', clientes.length, '\nsubtotal', subtotal, '\ncargos', cargos, '\nigv', igv);

      setIgv(igv);
      setCargos(cargos);
      setSubtotal(subtotal);

      setRows_cargos2(cargos2.map((val) => (val.id === 1 ? { ...val, valor: igv } : val)));
      setRows_cargos1(cargos1.map((val) => (val.id === 3 ? { ...val, valor: subtotal } : val)));
      setRows_precios(precios);
      setRows_clientes(
        clientes.map((val) => ({
          ...val,
          total: round(val.consumo * precios.find((dat) => dat.id === val.usar)?.precio + (cargos + igv) / clientes.length || 0),
        }))
      );
    },
    [rows_cargos1, rows_cargos2, rows_precios, rows_clientes]
  );

  return (
    <div className={`${darkMode} h-screen`}>
      <div className="h-full overflow-y-auto grid grid-cols-1 lg:grid-cols-2 bg-gray-300 dark:bg-gray-800">
        <div className="col-span-1 row-span-1 flex flex-col justify-center items-center lg:border-r-2 border-gray-700 dark:border-gray-500 p-3">
          <span className="mb-3 font-bold text-lg text-black dark:text-white">Clientes</span>
          <GridKnox
            className="w-full"
            rows={rows_clientes}
            columns={cols_clientes}
            setRows={(data) => update("clientes", data)}
            loading={rows_clientes.length === 0}
            processRowUpdate={(newRow: GridRowModel) => rows_clientes.map((val) => (val.id === newRow.id ? newRow : val))}
            headerHeight={35}
            rowHeight={35}
            disableSelectionOnClick
          />
        </div>

        <div className="col-span-1 row-span-1 flex flex-col justify-center items-center p-3">
          <span className="mb-3 font-bold text-lg text-black dark:text-white">Cargos</span>
          <GridKnox
            className="w-full"
            rows={rows_cargos1}
            columns={cols_cargos1}
            setRows={(data) => update("cargos1", data)}
            loading={rows_cargos1.length === 0}
            processRowUpdate={(newRow: GridRowModel) => rows_cargos1.map((val) => (val.id === newRow.id ? newRow : val))}
            headerHeight={35}
            rowHeight={35}
            disableSelectionOnClick
          />
        </div>

        <div className="col-span-1 row-span-1 flex flex-col justify-center items-center lg:border-t-2 lg:border-r-2 border-gray-700 dark:border-gray-500 p-3">
          <span className="mb-3 font-bold text-lg text-black dark:text-white">Precios</span>
          <GridKnox
            className="w-full"
            rows={rows_precios}
            columns={cols_precios}
            setRows={(data) => update("precios", data)}
            loading={rows_precios.length === 0}
            processRowUpdate={(newRow: GridRowModel) => rows_precios.map((val) => (val.id === newRow.id ? newRow : val))}
            headerHeight={35}
            rowHeight={35}
            disableSelectionOnClick
          />
        </div>

        <div className="col-span-1 row-span-1 flex flex-col justify-center items-center lg:border-t-2 border-gray-700 dark:border-gray-500 p-3">
          <span className="mb-3 font-bold text-lg text-black dark:text-white">Cargos</span>
          <GridKnox
            className="w-full"
            rows={rows_cargos2}
            columns={cols_cargos2}
            setRows={(data) => update("cargos2", data)}
            loading={rows_cargos2.length === 0}
            processRowUpdate={(newRow: GridRowModel) => rows_cargos2.map((val) => (val.id === newRow.id ? newRow : val))}
            headerHeight={35}
            rowHeight={35}
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
