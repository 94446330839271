// @ts-nocheck
import thunk from 'redux-thunk';
import {appReducer, authReducer} from '@reducers';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
	auth: authReducer,
	app: appReducer,
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
