import React, { useEffect, useState } from "react";
// import Box from '@mui/material/Box';
import { Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import SecurityIcon from '@mui/icons-material/Security';
import { randomId } from "@mui/x-data-grid-generator";
import {
  DataGrid,
  DataGridProps,
  GridActionsCellItem,
  GridCallbackDetails,
  GridColumns,
  GridEditRowsModel,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import { isFunction } from "@mui/x-data-grid/utils/utils";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "rgb(155,165,181,1)" : "rgb(107,114,128,1)"}`,
  color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
  fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(
    ","
  ),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  //? custom selector
  "& .MuiDataGrid-main": {
    //? second div
    "div:nth-of-type(2)": {
      //? in-line media query
      "@media (max-width: 1023px)": {
        styles: "height: auto !important", //? change style
      },
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    "@media (max-width: 1023px)": {
      styles: "height: auto !important",
    },
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: `1px solid ${theme.palette.mode === "light" ? "rgb(155,165,181,1)" : "rgb(107,114,128,1)"}`,
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    padding: 0,
    borderRight: `1px solid ${theme.palette.mode === "light" ? "rgb(155,165,181,1)" : "rgb(107,114,128,1)"}`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders, .MuiDataGrid-cell, .MuiDataGrid-row": {
    borderBottom: `1px solid ${theme.palette.mode === "light" ? "rgb(155,165,181,1)" : "rgb(107,114,128,1)"}`,
  },
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
    color: theme.palette.mode === "light" ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)",
  },
  "& .MuiPaginationItem-root, .MuiDataGrid-columnHeaders": {
    borderRadius: 0,
    // borderTop: `1px solid ${theme.palette.mode === 'light' ? 'rgb(155,165,181,1)' : 'rgb(107,114,128,1)'}`,
  },
  "& .final-cell, .MuiDataGrid-menuIcon": {
    marginRight: 0,
    borderRight: 0,
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    justifyContent: "center",
  },
}));

interface EditToolbarProps {
  setRows: (data: Array<any>) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

interface KnoxProps {
  onUpdate?: () => void;
  setRows?: (data: Array<any>) => void;
}

const GridKnox = (props: KnoxProps & DataGridProps & React.RefAttributes<HTMLDivElement>) => {
  const [rows, tmpRows] = useState(props.rows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const setRows = isFunction(props.setRows) ? props.setRows : tmpRows;

  useEffect(() => {
    tmpRows(props.rows);
  }, [props.rows]);

  function EditToolbar(params: EditToolbarProps) {
    const { setRows, setRowModesModel } = params;

    const handleClick = () => {
      const id = randomId();
      let resto = { id, isNew: true };

      props.columns.forEach((val) => (resto = { ...resto, [val.field]: "" }));
      setRows([...rows, resto]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Agregar
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const filtrado = rows.filter((row) => row.id !== id);
    setRows(filtrado);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedData: Array<any> = isFunction(props.processRowUpdate) && props.processRowUpdate(updatedRow, oldRow) ? props.processRowUpdate(updatedRow, oldRow) : updatedRow;
    setRows(updatedData);
    return updatedRow;
  };

  const columns: GridColumns = [
    ...props.columns,
    {
      field: "actions",
      type: "actions",
      minWidth: 75,
      flex: 0.1,
      cellClassName: "actions final-cell",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const caca = { icon: <SaveIcon />, label: "Save", onClick: handleSaveClick(id), color: "primary" };

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} color="primary" />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
          ];
        }

        return [
          row.editable !== false ? <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" /> : <></>,
          row.removable !== false ? <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" /> : <></>,
        ];
      },
    },
  ];

  const style = {
    // Adding media query..
    "@media (max-width: 1024px)": {
      height: "auto",
    },
  };

  return (
    // <Box
    //   sx={{
    //     height: 500,
    //     width: '100%',
    //     '& .actions': {
    //       color: 'text.secondary',
    //     },
    //     '& .textPrimary': {
    //       color: 'text.primary',
    //     },
    //   }}>
    <StyledDataGrid
      {...props}
      rows={rows}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      components={{
        Toolbar: EditToolbar,
      }}
      componentsProps={{
        toolbar: { setRows, setRowModesModel },
      }}
      experimentalFeatures={{ newEditingApi: true }}
    />
    // </Box>
  );
};

export default GridKnox;
