import { types } from '../../types/all';

const initial = {
	darkMode: 'ligth',
};

const appReducer = (state: any = initial, action: { type: string; [value: string]: any }) => {
	switch (action.type) {
		case types.dark:
			return {
				...state,
				darkMode: action[types.dark],
			};

		case types.ligth:
			return {
				...state,
				darkMode: action[types.dark],
			};

		case types.setAny:
			return {
				...state,
				...action[types.setAny],
			};

		default:
			return state;
	}
};

export default appReducer;
