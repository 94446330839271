import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";

const AppRouter = () => {
  // const { uid, name } = useSelector((state: any) => state.auth);
  const { darkMode } = useSelector((state: any) => state.app);
  const darkTheme = createTheme({
    palette: {
      mode: darkMode === "dark" ? "dark" : "light",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<label className="text-4xl font-bold">Login</label>} />
          <Route path="*" element={<label className="mx-auto">No existe la ruta especificada</label>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
