import { types } from '../../types/all';

const initial = {};

const authReducer = (state: any = initial, action: { type: string; [value: string]: any }) => {
	switch (action.type) {
		case types.login:
			return {
				...state,
				uid: action.uid,
				name: action.name,
			};

		case types.logout:
			const { uid, name, ...rest } = state;
			return { rest };

		default:
			return state;
	}
};

export default authReducer;
